<template>
  <div id="app">
    <Head></Head>
    <keep-alive :include="['home']">
       <router-view />
    </keep-alive>
    <Foot></Foot>
  </div>
</template>
<script>
import Head from "./components/headNav/index.vue";
import Foot from "./components/foot/index.vue";
export default {
  data() {
    return {};
  },
  components: {
    Head,
    Foot,
  },
};
</script>
<style>
#app {
  height: 100%;
  width: 100%;
  min-width: 1200px;
}
.center {
  width: 1200px;
  margin: 0 auto;
}
</style>
