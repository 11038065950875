import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import AmapVue from '@amap/amap-vue';
import 'element-ui/lib/theme-chalk/index.css'
import './assets/css/reset.css'

Vue.use(ElementUI)
Vue.use(AmapVue);

Vue.config.productionTip = false

AmapVue.config.version = '1.4.15'; // 默认2.0，这里可以不修改
// AmapVue.config.key = '16eae3675c23a93d1562f015f4b39b46';
AmapVue.config.key = '80ee816505d2f60ce6e3dd530036bfee';
// AmapVue.config.serviceHost = 'https://land.hzreal.cn/_AMapService';
AmapVue.config.plugins = [
    'AMap.ToolBar',
    'AMap.MoveAnimation',
    'AMap.Polygon'
    // 在此配置你需要预加载的插件，如果不配置，在使用到的时候会自动异步加载
];

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')