<template>
  <div class="headNav">
    <div class="headNav-content">
      <div class="leftLogo"></div>
      <div class="rightNav">
        <ul class="homeUl">
          <li
            @click="activeClick('home')"
            :class="['homeUl-li', active == 'home' ? 'dropdown_active' : '']"
          >
            <a
              :class="['a-color', active == 'home' ? 'homeUl-li-a' : '']"
              href="#/home"
              >首页</a
            >
            <!-- <ul class="nameUl">
            <li>1</li>
            <li>2</li>
            <li>3</li>
          </ul> -->
          </li>
          <li
            @click="activeClick('productService')"
            :class="[
              'homeUl-li',
              active == 'productService' ? 'dropdown_active' : '',
            ]"
          >
            <a href="#/productService" :class="['a-color', active == 'productService' ? 'homeUl-li-a' : '']"
              >产品服务</a
            >
          </li>
          <li
            @click="activeClick('enterpriseNews')"
            :class="[
              'homeUl-li',
              active == 'enterpriseNews' ? 'dropdown_active' : '',
              active == 'newsDetail' ? 'dropdown_active' : '',
            ]"
          >
            <a
              href="#/enterpriseNews"
              :class="['a-color', active == 'enterpriseNews' ? 'homeUl-li-a' : '',active == 'newsDetail' ? 'homeUl-li-a' : '']"
              >企业动态</a
            >
          </li>
          <li
            @click="activeClick('cooperationCases')"
            :class="[
              'homeUl-li',
              active == 'cooperationCases' ? 'dropdown_active' : '',
            ]"
          >
            <a href="#/cooperationCases" :class="['a-color', active == 'cooperationCases' ? 'homeUl-li-a' : '']"
              >合作案例</a
            >
          </li>
          <li
            @click="activeClick('aboutUs')"
            :class="[
              'homeUl-li',
              active == 'aboutUs' ? 'dropdown_active' : '',
            ]"
          >
            <a
              href="#/aboutUs"
              :class="['a-color', active == 'aboutUs' ? 'homeUl-li-a' : '']"
              >关于我们</a
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      active: "home",
    };
  },
  methods: {
    activeClick(tab) {
      this.active = tab;
    },
  },
  created(){
    this.active = sessionStorage.getItem('navName') || "home"
  },
  watch: {
    '$route' (val) {
        // 当前路由
        // console.log(val)
        if(val){
          sessionStorage.setItem('navName', val.name)
          this.active = sessionStorage.getItem('navName')
        }
        // 上一个路由
        // console.log(old)
    }
}
};
</script>

<style scoped>
.headNav {
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 500;
}
.headNav-content {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #203578;
}
.homeUl {
  height: 68px;
  line-height: 68px;
  /* padding: 30px 60px; */
  display: flex;
}
.homeUl-li {
  font-size: 16px;
  margin-right: 40px;
  cursor: pointer;
  padding: 5px 5px;
  position: relative;
}
.a-color {
  height: 100%;
  display: block;
  color: rgba(255, 255, 255, 0.4);
}
.homeUl-li-a {
  color: #fff;
}
.homeUl-li:hover:after {
  /* font-size: 40px; */
  width: 100%;
}
.homeUl-li::after {
  content: "";
  position: absolute;
  width: 0;
  height: 3px;
  overflow: hidden;
  background: #fff;
  bottom: -2px;
  transition: all 0.8s;
  left: 50%;
  transform: translate(-50%, -50%);
}
.homeUl-li:hover .nameUl {
  height: 100px;
}
.homeUl-li:hover a{
  color: #fff;
}
.nameUl {
  background-color: #ccc;
  position: absolute;
  top: 68px;
  left: 0;
  width: 100%;
  height: 0;
  overflow: hidden;
  transition: height 0.5s ease;
}
.dropdown_active::after {
  transition: unset !important;
  background: #fff;
  width: 100% !important;
}
.leftLogo {
  height: 69px;
  width: 164px;
  background-image: url('../../assets/image/logo-top.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
</style>