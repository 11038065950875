<template>
  <div class="footer">
    <div class="content center">
      <div class="foot-left">
        <div class="foot-left-name"></div>
        <div class="foot-left-address">
          <i class="el-icon-location-outline"></i>
          <span>办公地点：杭州市西湖区华星路99号创业大厦B612</span>
        </div>
        <div class="foot-text">
          <span>杭州瑞懿科技有限公司</span>
          <span>浙ICP备17030141号-2</span>
        </div>
      </div>
      <el-divider direction="vertical"></el-divider>
      <div class="foot-center">
        <div class="foot-center-left">
          <div class="title">联系我们</div>
          <div class="title-items">联系电话：0571-87676972</div>
          <div class="title-items">办公时间：09：00-16：00</div>
          <!-- <div class="title-items">商业沟通：157-0011-0423</div> -->
        </div>
        <div class="foot-center-center">
          <div class="title">案例</div>
          <div class="title-items"><a href="#/productService">查看产品</a></div>
          <div class="title-items"><a>企业合作</a></div>
        </div>
        <div class="foot-center-right">
          <div class="title">关于</div>
          <div class="title-items"><a href="#/aboutUs">关于瑞懿</a></div>
          <div class="title-items"><a href="#/aboutUs">联系我们</a></div>
          <div class="title-items"><a>企业文化</a></div>
        </div>
      </div>
      <el-divider direction="vertical"></el-divider>
      <div class="foot-right">
        <div class="foot-right-left">
          <div class="left-img"></div>
          <div class="foot-right-title">公众号</div>
        </div>
        <div class="foot-right-right">
          <div class="right-img"></div>
          <div class="foot-right-title">APP下载</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>
<style scoped>
.footer {
  width: 100%;
  background-color: #00143d;
  color: #fff;
}
.content {
  height: 100%;
  padding: 80px 0px 80px 0px;
  display: flex;
}
.foot-left {
  width: 430px;
  height: 100%;
}
.foot-center {
  width: 560px;
  height: 100%;
  padding: 0 60px;
  display: flex;
  justify-content: space-between;
}
.title {
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  margin-bottom: 30px;
}
.title-items {
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  opacity: 0.4;
  margin-bottom: 16px;
}
.title-items a {
  color: #ffffff;
}
.foot-right {
  width: 210px;
  height: 100%;
  padding: 36px 10px;
  display: flex;
  justify-content: space-between;
}
.left-img {
  width: 80px;
  height: 80px;
  background-color: bisque;
}
.right-img {
  width: 80px;
  height: 80px;
  background-color: bisque;
}
.foot-right-title {
  width: 80px;
  height: 28px;
  line-height: 38px;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  opacity: 0.5;
}
.foot-left-name {
  height: 106px;
  width: 220px;
  background-image: url("../../assets/image/logo-foot.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.foot-left-address {
  height: 36px;
  width: 358px;
  font-size: 14px;
  line-height: 36px;
  background-color: rgba(0, 122, 255, 0.5);
}
.foot-left-address i {
  margin: 0 10px;
}
.foot-text {
  font-size: 14px;
  margin-top: 20px;
}
.foot-text span:nth-child(1) {
  margin-right: 15px;
}
::v-deep .el-divider--vertical {
  height: 180px;
  background-color: rgba(255, 255, 255, 0.2);
}
</style>