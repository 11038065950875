var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"headNav"},[_c('div',{staticClass:"headNav-content"},[_c('div',{staticClass:"leftLogo"}),_c('div',{staticClass:"rightNav"},[_c('ul',{staticClass:"homeUl"},[_c('li',{class:['homeUl-li', _vm.active == 'home' ? 'dropdown_active' : ''],on:{"click":function($event){return _vm.activeClick('home')}}},[_c('a',{class:['a-color', _vm.active == 'home' ? 'homeUl-li-a' : ''],attrs:{"href":"#/home"}},[_vm._v("首页")])]),_c('li',{class:[
            'homeUl-li',
            _vm.active == 'productService' ? 'dropdown_active' : '',
          ],on:{"click":function($event){return _vm.activeClick('productService')}}},[_c('a',{class:['a-color', _vm.active == 'productService' ? 'homeUl-li-a' : ''],attrs:{"href":"#/productService"}},[_vm._v("产品服务")])]),_c('li',{class:[
            'homeUl-li',
            _vm.active == 'enterpriseNews' ? 'dropdown_active' : '',
            _vm.active == 'newsDetail' ? 'dropdown_active' : '',
          ],on:{"click":function($event){return _vm.activeClick('enterpriseNews')}}},[_c('a',{class:['a-color', _vm.active == 'enterpriseNews' ? 'homeUl-li-a' : '',_vm.active == 'newsDetail' ? 'homeUl-li-a' : ''],attrs:{"href":"#/enterpriseNews"}},[_vm._v("企业动态")])]),_c('li',{class:[
            'homeUl-li',
            _vm.active == 'cooperationCases' ? 'dropdown_active' : '',
          ],on:{"click":function($event){return _vm.activeClick('cooperationCases')}}},[_c('a',{class:['a-color', _vm.active == 'cooperationCases' ? 'homeUl-li-a' : ''],attrs:{"href":"#/cooperationCases"}},[_vm._v("合作案例")])]),_c('li',{class:[
            'homeUl-li',
            _vm.active == 'aboutUs' ? 'dropdown_active' : '',
          ],on:{"click":function($event){return _vm.activeClick('aboutUs')}}},[_c('a',{class:['a-color', _vm.active == 'aboutUs' ? 'homeUl-li-a' : ''],attrs:{"href":"#/aboutUs"}},[_vm._v("关于我们")])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }