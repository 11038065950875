import Vue from 'vue'
import VueRouter from 'vue-router'
const Home = () =>
    import ('../views/home/index.vue')
const AboutUs = () =>
    import ('../views/aboutUs/aboutUs.vue')
const EnterpriseNews = () =>
    import ('../views/enterpriseNews/enterpriseNews.vue')
const ProductService = () =>
    import ('../views/productService/productService.vue')
const CooperationCases = () =>
    import ('../views/cooperationCases/cooperationCases.vue')
const NewsDetail = () =>
    import ('../views/enterpriseNews/newsDetail.vue')

Vue.use(VueRouter)

const routes = [{
        path: '/',
        redirect: '/home'
    },
    {
        path: '/home',
        name: 'home',
        component: Home
    },
    {
        path: '/aboutUs',
        name: 'aboutUs',
        component: AboutUs
    },
    {
        path: '/enterpriseNews',
        name: 'enterpriseNews',
        component: EnterpriseNews
    },
    {
        path: '/newsDetail',
        name: 'newsDetail',
        component: NewsDetail
    },
    {
        path: '/productService',
        name: 'productService',
        component: ProductService
    },
    {
        path: '/cooperationCases',
        name: 'cooperationCases',
        component: CooperationCases
    }
]

const router = new VueRouter({
    routes
})

export default router